import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import themeObject from 'styles/theme';
import Loader from './Loader';

const clrLib = {
  light: {
    primary: themeObject.color.white,
    secondary: themeObject.color.black,
  },
  medium: {
    primary: themeObject.color.grayLighter,
    secondary: themeObject.color.gray,
  },
  dark: {
    primary: themeObject.color.black,
    secondary: themeObject.color.white,
  },
};

const sizes = {
  small: '80px',
  medium: '120px',
  large: '140px',
};

const common = css`
  border: 1px solid ${({ color, inverted }) => (inverted ? 'transparent' : clrLib[color].primary)};
  color: ${({ color, inverted }) => (inverted ? clrLib[color].secondary : clrLib[color].primary)};
  background: transparent;
  text-decoration: none;
  min-width: ${({ size }) => sizes[size]};
  padding: 1rem 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.font.m};
  cursor: pointer;
  min-height: 37px;
  letter-spacing: 2px;
  font-weight: 300;
  transition: .2s ease;
  background: ${({ color, inverted }) => inverted && clrLib[color].primary};
  position: relative;
  box-sizing: border-box;
  &:hover {
    color: ${({ color, inverted }) => (inverted ? clrLib[color].primary : clrLib[color].secondary)};
    background: ${({ color, inverted }) => (inverted ? 'transparent' : clrLib[color].primary)};
    border: 1px solid ${({ color, inverted }) => (inverted ? clrLib[color].primary : 'transparent')};
    font-weight: 400;
    path {
      fill: ${({ color, inverted }) => (inverted ? clrLib[color].primary : clrLib[color].secondary)};
    }
  }
  &:focus {
    outline: none;
    color: ${({ color }) => clrLib[color].secondary};
    background: ${({ color }) => clrLib[color].primary};
    font-weight: 400;
    path {
      fill: ${({ color, inverted }) => (inverted ? clrLib[color].primary : clrLib[color].secondary)};
    }
  }
`;


const StyledButton = styled.button`${common}`;
const StyledAnchor = styled.a`${common}`;
const StyledLink = styled(Link)`${common}`;

const Button = ({
  children, component, loading, to, ...rest
}) => {
  const { color, inverted } = rest;
  const content = loading
    ? <Loader fill={inverted ? clrLib[color].secondary : clrLib[color].primary} />
    : children;
  switch (component) {
    case 'a': return <StyledAnchor href={to} {...rest}>{content}</StyledAnchor>;
    case 'button': return <StyledButton {...rest}>{content}</StyledButton>;
    default: return <StyledLink to={to} {...rest}>{content}</StyledLink>;
  }
};

Button.defaultProps = {
  component: 'link',
  color: 'dark',
  size: 'medium',
};

Button.propTypes = {
  component: PropTypes.oneOf(['link', 'a', 'button']),
  color: PropTypes.oneOf(['light', 'dark', 'medium']),
  size: PropTypes.oneOf(['medium', 'big', 'small']),
  inverted: PropTypes.bool,
};

export default Button;
